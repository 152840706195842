import { globalState } from '../../../shared/foreground/models';
import type { KeyboardShortcut } from '../../../shared/types/keyboardShortcuts';
import createKeyboardShortcutEvent from './createKeyboardShortcutEvent';

export default function executeKeyboardShortcut(keys: KeyboardShortcut['keys']) {
  const shortcutsFound = Object.values(globalState.getState().keyboardShortcuts).filter(
    (keyboardShortcut) => keyboardShortcut.keys === keys,
  );

  if (!shortcutsFound.length) {
    throw new Error('No such shortcut those keys in state.keyboardShortcuts');
  }

  if (shortcutsFound.length > 1) {
    throw new Error('More than one shortcut with those keys in state.keyboardShortcuts');
  }

  shortcutsFound[0].callback(createKeyboardShortcutEvent('programmatic'));
}
