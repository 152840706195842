import React, { useCallback, useEffect, useMemo } from 'react';

import { setCmdPaletteOpen } from '../../../../../shared/foreground/cmdPalette';
import { globalState } from '../../../../../shared/foreground/models';
import executeKeyboardShortcut from '../../../utils/executeKeybordShortcut';
import { getDisplayShortcutKeys } from '../../../utils/shortcuts/shortcuts';
import type { InternalPaletteAction, PaletteAction as TPaletteAction } from './PaletteAction';
import { PaletteAction } from './PaletteAction';

export default function ShortcutPaletteAction({
  isOnHome,
  shouldErrorIfKeyboardShorcutNotInState = !isOnHome,
  unstyledShortcut,
  ...props
}: {
  isOnHome: boolean;
  label: string; // Required by command palette system but not used in here. Don't remove
  shouldErrorIfKeyboardShorcutNotInState?: boolean;
  unstyledShortcut: string | string[];
  uniqueId: string;
} & Pick<TPaletteAction, 'focused' | 'tags' | 'mainTitleType'>) {
  const _unstyledShortcut = useMemo(() => {
    return typeof unstyledShortcut === 'string' ? unstyledShortcut : unstyledShortcut[0];
  }, [unstyledShortcut]);

  const keyboardShortcut = globalState(
    useCallback(
      (state) => Object.values(state.keyboardShortcuts).find(({ keys }) => keys === _unstyledShortcut),
      [_unstyledShortcut],
    ),
  );

  const action = useCallback(() => {
    setCmdPaletteOpen(false, { userInteraction: 'unknown' });
    executeKeyboardShortcut(_unstyledShortcut);
  }, [_unstyledShortcut]);

  const args: Omit<InternalPaletteAction, 'children'> = useMemo(
    () => ({
      ...props,
      action,
      label: keyboardShortcut?.description,
      shortcut: getDisplayShortcutKeys(_unstyledShortcut),
    }),
    [action, keyboardShortcut?.description, props, _unstyledShortcut],
  );

  useEffect(() => {
    if (!keyboardShortcut && shouldErrorIfKeyboardShorcutNotInState) {
      throw new Error(`Can't find keyboard shortcut (${_unstyledShortcut}) in state.keyboardShortcuts`);
    }
  }, [_unstyledShortcut, keyboardShortcut, shouldErrorIfKeyboardShorcutNotInState]);

  if (!keyboardShortcut) {
    return null;
  }

  return <PaletteAction {...args}>{keyboardShortcut?.description}</PaletteAction>;
}
