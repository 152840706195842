import React, { useCallback } from 'react';

import { setCmdPaletteOpen, setCmdPaletteSubMenu } from '../../../../shared/foreground/cmdPalette';
import { globalState } from '../../../../shared/foreground/models';
import { SubMenu } from '../../../../shared/types';
import { ShortcutId } from '../../../../shared/types/keyboardShortcuts';
import { useKeyboardShortcutPreventDefault } from '../../hooks/useKeyboardShortcut';
import { useShortcutsMap } from '../../utils/shortcuts';
import { BulkActionsPalette } from './BulkActionsPalette';
import styles from './CommandPalette.module.css';
import { FeedbackPalette } from './FeedbackPalette';
import { EditFeedPalette, FeedsPalette } from './FeedsPalette';
import {
  EditViewPalette,
  FilterAllDocsPalette,
  SaveFilterPalette,
  SplitByPalette,
  ViewFiltersPalette,
} from './FiltersPalette';
import { GeneralPalette, ReferFriendPalette, SaveDocFromUrlPalette } from './GeneralPalette';
import {
  DocumentGptPromptPalette,
  HighlightGptPromptPalette,
  InputGptPromptPalette,
  ManualGptPromptPalette,
} from './GptPromptPalette';
import { BugReportPalette, FeatureRequestPalette, ReportingPalette } from './ReportingPalette';
import { ShortcutsPalette } from './ShortcutsPalette';
import { EditTagNamePalette, TagsPalette } from './TagsPalette';

export default function CommandPalette() {
  const isOpen = globalState(useCallback((state) => state.cmdPalette.isOpen, []));
  const subMenu = globalState(useCallback((state) => state.cmdPalette.subMenu, []));
  const shortcutsMap = useShortcutsMap();

  useKeyboardShortcutPreventDefault(
    shortcutsMap[ShortcutId.OpenCmdPalette],
    useCallback(() => {
      setCmdPaletteSubMenu(SubMenu.Normal, { userInteraction: 'keypress' });
    }, []),
  );

  if (!isOpen) {
    return null;
  }

  const isBulkActionsPalette =
    subMenu === SubMenu.BulkActions ||
    subMenu === SubMenu.BulkActionsTagsAll ||
    subMenu === SubMenu.BulkActionsTagsAbove ||
    subMenu === SubMenu.BulkActionsTagsBelow;

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div
      className={styles.paletteWrapper}
      id="command-palette"
      onClick={() => setCmdPaletteOpen(false, { userInteraction: 'click' })}
    >
      {subMenu === SubMenu.AccountIssue && <ReportingPalette />}
      {subMenu === SubMenu.Normal && <GeneralPalette />}
      {subMenu === SubMenu.Feeds && <FeedsPalette />}
      {subMenu === SubMenu.Tags && <TagsPalette />}
      {subMenu === SubMenu.EditTag && <EditTagNamePalette />}
      {subMenu === SubMenu.FeatureRequest && <FeatureRequestPalette />}
      {subMenu === SubMenu.BugReport && <BugReportPalette />}
      {subMenu === SubMenu.Feedback && <FeedbackPalette />}
      {subMenu === SubMenu.FeedbackReport && <ReportingPalette />}
      {subMenu === SubMenu.HighlightGptPrompt && <HighlightGptPromptPalette />}
      {subMenu === SubMenu.DocumentGptPrompt && <DocumentGptPromptPalette />}
      {subMenu === SubMenu.ManualGptPrompt && <ManualGptPromptPalette />}
      {subMenu === SubMenu.InputGptPrompt && <InputGptPromptPalette />}
      {subMenu === SubMenu.Shortcuts && <ShortcutsPalette />}
      {subMenu === SubMenu.FilterAllDocuments && <FilterAllDocsPalette fillInputWithQuery />}
      {subMenu === SubMenu.CreateFilteredView && <FilterAllDocsPalette />}
      {subMenu === SubMenu.ParseError && <ReportingPalette />}
      {subMenu === SubMenu.ProductQuestion && <ReportingPalette />}
      {subMenu === SubMenu.SaveFilteredView && <SaveFilterPalette />}
      {subMenu === SubMenu.SaveDocFromUrl && <SaveDocFromUrlPalette />}
      {subMenu === SubMenu.BrowseFilteredView && <ViewFiltersPalette />}
      {subMenu === SubMenu.SelectSplitBy && <SplitByPalette />}
      {subMenu === SubMenu.EditView && <EditViewPalette />}
      {subMenu === SubMenu.EditFeed && <EditFeedPalette />}
      {subMenu === SubMenu.ReferFriend && <ReferFriendPalette />}
      {isBulkActionsPalette && <BulkActionsPalette />}
    </div>
  );
}
